<template>
	<div class="sliderfield field">
		<label  :for="fieldname">{{label}}<span v-if="isRequired" class="req">&nbsp;*</span></label>
		<div class="sliderAndInput">
			
			<!-- Left block -->
			<div class="sliderElements">

				<div class="gradations">
					<div class="inside">
						<div>
							<span v-if="type!=2" class="legend">0</span>
							<span v-if="type==2" class="grad_smiley">&#128542;</span>
							<!-- disapointed: &#128542;-->
							<!-- angry: &#128544; -->
						</div>
						<div>
							<span v-if="type!=2" class="legend">{{correctedSteps/2}}</span>
							<span v-if="type==2" class="grad_smiley">&#128528;</span>
						</div>
						<div>
							<span v-if="type!=2" class="legend">{{correctedSteps}}</span>
							<span v-if="type==2" class="grad_smiley">&#128515;</span>
							<!-- smile: &#128515;-->
							<!-- love: &#128525; -->
						</div>
					</div>
				</div>

				<div class="sliderposition">
					<input type="range" min="0" :max="correctedSteps" class="slider" 
					:class="[
						'smile'+sliderValue, 
						type==3 ? 'smile': '',
						'colorknob'+sliderValue, 
						type==4 ? 'colorknob': '',
						type==5 ? 'backgroundGradient' : '' ,
						type==6 ? 'backgroundColor' : ''
						]" :value="sliderValue" 
						@input="e => setValue(e)">
					</div>
				</div>

				<!-- Right block -->
				<div class="inputElement">
					<input type="number" :value="sliderValue" @input="e => setValue(e)" :class="{'hasSuccess': isInSuccess}" :id="fieldname">
				</div>
			</div>

			<div class="helper" role="textbox" :aria-label="'More info for ' + label + ' field'">{{helper}}</div>
		</div>

	</template>

	<!-- ================================================================================== -->

	<script>
		import FormFields from "../mixins/FormFields";
		import customLog from "../mixins/CustomLog";

		export default {
			name:"",
			mixins: [
				customLog,
				FormFields,
				],
			props: {
				steps: {
					type: Number,
					required: false,
				default: 10
				},
				type: {
					type: Number,
					required: false,
				default: 1
				},
				defaultValue: {
					type: Number,
					required: true,
				}
			},
			data(){
				return {
					sliderValue: 5,
				}
			},
			methods: {
				setValue(event){
					if (event.target.value > parseInt(this.steps)){event.target.value = parseInt(this.steps)}
						this.sliderValue = parseInt(event.target.value);
					this.sendDataToForm();
					this.validateField();
				},

				sendDataToForm(){
					this.$emit('update:modelValue', this.sliderValue);
				},

				validateField(){
				this.giveSuccess(); // no wrong answers.
			},
		},


		computed: {
			correctedSteps(){
				if(this.type === 3){
					return 10; // Type 3 don't work with any other value.	
				}else{
					return this.steps;
				}
			}
		},

		created(){
			this.sliderValue = this.defaultValue;
		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
